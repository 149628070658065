<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!--제안기간-->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="제안기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 등록부서 -->
          <c-dept
            :isFirstValue="false"
            type="search"
            name="deptCd"
            label="제안부서"
            v-model="searchParam.deptCd"
          ></c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 개선요청부서 -->
          <c-dept
            :isFirstValue="false"
            type="search"
            name="execDeptCd"
            label="개선실행부서"
            v-model="searchParam.execDeptCd"
          ></c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행상태 -->
          <c-select
            type="search"
            stepperGrpCd="SAI_SUGGESTION_STATUS_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="suggestionStepCd"
            label="진행상태"
            v-model="searchParam.suggestionStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!--제안 목록-->
    <c-table
      ref="table"
      title="제안 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="saiSobBoardOpinionId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--등록-->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="suggestionPopup" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'suggestion',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'suggestionNo',
            field: 'suggestionNo',
            label: '제안번호',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'suggestDivisionTypeName',
            field: 'suggestDivisionTypeName',
            label: '제안구분',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'suggestCategoryTypeName',
            field: 'suggestCategoryTypeName',
            label: '제안유형',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'suggestionTitle',
            field: 'suggestionTitle',
            //제목
            label: 'LBLTITLE',
            align: 'left',
            type: "link",
            style: 'width:300px',
            sortable: true,
          },
          {
            name: 'regName',
            field: 'regName',
            label: '제안자',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'suggestionDate',
            field: 'suggestionDate',
            label: '제안일자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'execDeptName',
            field: 'execDeptName',
            label: '개선실행부서',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'suggestionStepName',
            field: 'suggestionStepName',
            label: '진행상태',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            // 결재진행단계
            name: 'approvalStatusName',
            field: 'approvalStatusName',
            label: 'LBL0000212',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'suggestionFirstAuditName',
            field: 'suggestionFirstAuditName',
            label: '1차<br/>심사결과',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
          {
            name: 'suggestionSecondAuditName',
            field: 'suggestionSecondAuditName',
            label: '2차<br/>심사결과',
            align: 'center',
            style: 'width:80px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        deptCd: null,
        execDeptCd: null,
        suggestionStepCd: null,
        period: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        isFull: true,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.sug.suggestion.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (this.searchParam.period) {
        this.searchParam.startYmd = this.searchParam.period[0];
        this.searchParam.endYmd = this.searchParam.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.suggestionPopup(row);
    },
    suggestionPopup(result) {
      this.popupOptions.target = () => import(`${"./suggestionDetail.vue"}`);
      this.popupOptions.title = '제안제도 상세';   //제안제도 상세
      this.popupOptions.param = {
        saiSugSuggestionId: result ? result.saiSugSuggestionId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
